<template>
    <div class="agreement-content_container">
        <section class="section2">
            <article class="section2Content">
                <h3 class="sectionContentSubTitle">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    长沙盛世隆和网络科技有限公司（以下简称“我们”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，我们制定本《可乐漂流瓶隐私权政策》（下称“本政策
                    /本隐私权政策”）并提醒您：
                    本政策适用于可乐漂流瓶产品或服务。如我们关联公司的产品或服务中使用了可乐漂流提供的产品或服务（例如直接使用可乐漂流账户登录）但未设独立隐私权政策的，则本政策同样适用于该部分产品或服务。
                    需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于可乐漂流瓶中已另行独立设置隐私权政策的产品或服务。
                    在使用可乐漂流瓶各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用可乐漂流瓶各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过可乐漂流瓶提供的各种联系方式与我们联系。
                    本隐私权政策部分将帮助您了解以下内容：
                </h3>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    本隐私权政策部分将帮助您了解以下内容：
                </h2>
                <p class="sectionContent">
                    1、我们如何收集和使用您的个人信息<br />
                    2、我们如何使用 Cookie 和同类技术<br />
                    3、我们如何共享、转让、公开披露您的个人信息<br />
                    4、我们如何保护您的个人信息<br />
                    5、您如何管理您的个人信息<br />
                    6、我们如何处理未成年人的个人信息<br />
                    7、您的个人信息如何在全球范围转移<br />
                    8、本隐私权政策如何更新<br />
                    9、如何联系我们
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    一、我们如何收集和使用您的个人信息
                </h2>
                <p class="sectionContent">
                    个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，我们会出于本政策所述的以下目的，收集和使用您的个人信息：
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">（一）帮助您成为我们的用户</h2>
                <p class="sectionContent">
                    为完成创建账号，以便我们为您提供服务，您需要提供以下信息：您的昵称、头像、性别、出生年月日、手机号码/微信号/QQ号，并创建用户名和密码。
                    在注册过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供更好的服务和体验：您的真实姓名、真实证件信息、家乡、情感状态、星座、职业、学校、您本人的真实头像。但如果您不提供这些信息，将不会影响使用可乐漂流瓶产品或服务的基本功能。您提供的上述信息将在您使用本服务期间持续授权我们使用。在您主动注销账号时，我们将根据适用法律法规的要求尽快使其匿名或删除您的个人信息。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    （二）为您展示和推送商品或服务
                </h2>
                <p class="sectionContent">
                    为改善我们的产品或服务、向您提供个性化的信息搜索及交易服务，我们会根据您的浏览及搜索记录、设备信息、位置信息、交易信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，基于特征标签进行间接人群画像并展示、推送信息。
                    如果您不想接受我们给您发送的商业广告，您可随时通过相应产品退订功能取消
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">（三）向您提供商品或服务</h2>
                <p class="sectionContent">
                    1、您向我们提供的信息
                    您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件、银行卡号或支付宝账号等；
                    您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。向可乐漂流瓶提供该其他方的前述个人信息之前，您需确保您已经取得其授权同意。<br />
                    2、我们在您使用服务过程中收集的信息
                    为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
                    设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP
                    地址、GPS位置以及能够提供相关信息的Wi-Fi
                    接入点、蓝牙和基站等传感器信息，因我们提供的服务系基于地理位置提供的移动社交服务，您确认成功注册“可乐漂流瓶”帐号视为确认授权我们提取、公开及使用您的地理位置信息。如您需要终止向其他用户公开其地理位置信息，可随时自行设置为隐身状态）。
                    日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。
                    请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
                    当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。<br />
                    3、我们通过间接获得方式收集到的您的个人信息
                    您可通过可乐漂流瓶账号在我们提供的链接入口使用我们关联公司提供的产品或服务，为便于我们基于关联账号共同向您提供一站式服务并便于您统一进行管理，我们在可乐漂流瓶集中展示您的信息或推荐您感兴趣的信息。您可以通过可乐漂流瓶首页、“更多”等功能发现、使用上述服务。当您通过我们产品或服务使用上述服务时，您授权我们根据实际业务及合作需要从我们关联公司处接收、汇总、分析我们确认其来源合法或您授权同意其向我们提供的您的个人信息或交易信息。<br />
                    如您拒绝提供上述信息或拒绝授权，可能无法使用我们关联公司的相应产品或服务，或者无法展示相关信息，但不影响使用可乐漂流瓶浏览、聊天、发布动态等核心服务。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">（四）为您提供安全保障</h2>
                <p class="sectionContent">
                    请注意，为确保用户身份真实性、向您提供更好的安全保障，您可以向我们提供身份证、军官证、护照、驾驶证、社保卡、居住证等身份信息、面部特征等生物识别信息、芝麻信用等个人敏感信息完成实名认证。如您拒绝提供上述信息，可能无法使用账户管理、继续可能存在风险的交易等服务，但不会影响您使用浏览、聊天等服务。<br />
                    为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或可乐漂流瓶相关协议规则的情况，我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">（五）其他用途</h2>
                <p class="sectionContent">
                    我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">（六）征得授权同意的例外</h2>
                <p class="sectionContent">
                    根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：<br />
                    1、与国家安全、国防安全有关的；<br />
                    2、与公共安全、公共卫生、重大公共利益有关的；<br />
                    3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />
                    4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br />
                    5、所收集的个人信息是您自行向社会公众公开的<br />；
                    6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />
                    7、根据您的要求签订合同所必需的；<br />
                    8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br />
                    9、为合法的新闻报道所必需的；<br />
                    10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br />
                    11、法律法规规定的其他情形。<br />
                    如我们停止运营可乐漂流瓶产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    二、我们如何使用 Cookie 和同类技术
                </h2>
                <p class="sectionContent">
                    （一）Cookie<br />
                    为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为
                    Cookie 的小数据文件。Cookie
                    通常包含标识符、站点名称以及一些号码和字符。借助于
                    Cookie，网站能够存储您的偏好等数据。<br />
                    （二）网站信标和像素标签<br />
                    除 Cookie
                    外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    三、我们如何共享、转让、公开披露您的个人信息
                </h2>
                <p class="sectionContent">
                    （一）共享<br />
                    我们不会与可乐漂流瓶服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：<br />
                    1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br />
                    2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br />
                    3、与关联公司间共享：为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护可乐漂流瓶关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息（如为便于您使用可乐漂流瓶账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。<br />
                    4、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。<br />
                    目前，我们的授权合作伙伴包括以下类型：<br />
                    （1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别个人身份的统计信息（例如“男性，25-29岁，位于北京”），帮助他们了解其受众或顾客。<br />
                    （2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。<br />
                    对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。<br />
                    （二）转让<br />
                    我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br />
                    1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；<br />
                    2、在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。<br />
                    （三）公开披露<br />
                    我们仅会在以下情况下，公开披露您的个人信息：<br />
                    1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；<br />
                    2、如果我们确定您出现违反法律法规或严重违反可乐漂流瓶相关协议规则的情况，或为保护可乐漂流瓶及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或可乐漂流瓶相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及可乐漂流瓶已对您采取的措施。<br />
                    （四）共享、转让、公开披露个人信息时事先征得授权同意的例外<br />
                    以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br />
                    1、与国家安全、国防安全有关的；<br />
                    2、与公共安全、公共卫生、重大公共利益有关的；<br />
                    3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />
                    4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />
                    5、您自行向社会公众公开的个人信息；<br />
                    6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br />
                    根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    四、我们如何保护您的个人信息安全
                </h2>
                <p class="sectionContent">
                    （一）我们已采取符合业界通用解决方案、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据（如信用卡信息）时受
                    SSL（Secure Socket
                    Layer）协议加密保护；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br />
                    （二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。<br />
                    （三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br />
                    （四）互联网并非绝对安全的环境，我们强烈建议您不要使用非可乐漂流瓶推荐的通信方式发送个人信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。<br />
                    如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络可乐漂流瓶客服，以便我们根据您的申请采取相应措施。<br />
                    请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发表动态或者在群聊天、圈子等公众场合选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。<br />
                    请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。
                    同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">五、您如何管理您的个人信息</h2>
                <p class="sectionContent">
                    您可以通过以下方式访问及管理您的个人信息：
                    （一）访问您的个人信息<br />
                    您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：
                    账户信息——如果您希望访问或编辑您的账户中的个人证件信息，手机绑定、支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过登录账号通过“更多”-“设置”-“账号与安全”执行此类操作。<br />
                    个人资料—如果您希望访问或编辑您个人资料中的昵称、头像、家乡、情感状态、出生年月日、星座、职业、学校、兴趣爱好以及地点足迹其他资料等，您可以通过登录账户通过“更多”-“查看或编辑个人资料”执行此类操作。<br />
                    动态信息——您可以登录账号通过“我的动态”访问或清除您的动态发表历史记录。<br />
                    如果您无法通过上述路径访问该等个人信息，您可以随时通过可乐漂流瓶客服与我们取得联系。我们将在30天内回复您的访问请求。<br />
                    对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（七）响应您的上述请求”中的相关安排向您提供。<br />
                    （二）更正或补充您的个人信息<br />
                    当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。<br />
                    （三）删除您的个人信息<br />
                    您可以通过本条“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。
                    在以下情形中，您可以向我们提出删除个人信息的请求：<br />
                    1、如果我们处理个人信息的行为违反法律法规；<br />
                    2、如果我们收集、使用您的个人信息，却未征得您的明确同意；<br />
                    3、如果我们处理个人信息的行为严重违反了与您的约定；<br />
                    4、如果您不再使用我们的产品或服务，或您主动注销了账号；<br />
                    5、如果我们永久不再为您提供产品或服务。<br />
                    若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。<br />
                    当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br />
                    （四）改变您授权同意的范围<br />
                    每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一条”）。除此之外，对于额外个人信息的收集和使用，您可以在更多-设置或与可乐漂流瓶客服联系给予或收回您的授权同意（例如，您可以在可乐漂流瓶momo手机APP“更多-设置-隐私”中收回您同意其他用户通过手机号码找到您的授权）。<br />
                    当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。<br />
                    （五）个人信息主体注销账户<br />
                    如用户注册“可乐漂流瓶”帐号后一年不登录，可乐漂流瓶可以收回该帐号，以免造成资源浪费，由此造成的不利后果由用户自行承担，用户确认操作之前与可乐漂流瓶相关的所有服务均已进行妥善处理。回收账号后并不代表本可乐漂流瓶账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，用户的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，可乐漂流瓶有限自行终止用户的账号注销并无需另行得到用户的同意。<br />
                    用户主动注销账号，注销账号15天内，停止使用任何功能，到时间后系统将删除用户所有账号信息，系统会保留用于名字和头像，移除用于其他所有相关信息。用户后续无法继续使用该用户名进行登陆或注册<br />
                    （六）约束信息系统自动决策<br />
                    在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害可乐漂流瓶商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。<br />
                    （七）响应您的上述请求<br />
                    为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br />
                    我们将在30天内做出答复。如您不满意，还可以通过可乐漂流瓶客服发起投诉。<br />
                    对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。<br />
                    在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br />
                    1、与国家安全、国防安全有关的；<br />
                    2、与公共安全、公共卫生、重大公共利益有关的；<br />
                    3、与犯罪侦查、起诉、审判和执行判决等有关的；<br />
                    4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br />
                    5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br />
                    6、涉及商业秘密的。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    六、我们如何处理未成年人的个人信息
                </h2>
                <p class="sectionContent">
                    如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。<br />
                    对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    七、您的个人信息如何在全球范围转移
                </h2>
                <p class="sectionContent">
                    我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：<br />
                    1、法律法规有明确规定；<br />
                    2、获得您的明确授权；<br />
                    3、您通过互联网进行跨境发布动态等个人主动行为。<br />
                    针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">八、本隐私权政策如何更新</h2>
                <p class="sectionContent">
                    我们的隐私权政策可能变更。
                    未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括我们会通过可乐漂流瓶公示的方式进行通知甚至向您提供弹窗提示）。<br />
                    本政策所指的重大变更包括但不限于：<br />
                    1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br />
                    2、我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；<br />
                    3、个人信息共享、转让或公开披露的主要对象发生变化；<br />
                    4、您参与个人信息处理方面的权利及其行使方式发生重大变化；<br />
                    5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br />
                    6、个人信息安全影响评估报告表明存在高风险时。<br />
                    我们还会将本隐私权政策的旧版本存档，供您查阅。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">九、如何联系我们</h2>
                <p class="sectionContent">
                    您可以通过以下方式与我们联系，我们将在30天内回复您的请求：<br />
                    1、如对本政策内容有任何疑问、意见或建议，您可通过可乐漂流瓶在线客服与我们联系<br />

                    2、如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，双方同意由本服务条款签订地有管辖权的人民法院诉讼管辖，本服务条款签订地为连云港市海州区.
                </p>
            </article>
        </section>
    </div>
</template>

<script>
export default {
    name: "ZhUse",
}
</script>
