<template>
    <div class="agreement-content-wrapper flex flex-col">
        <h1 class="title">{{ $t("page.useAgreement") }}</h1>
        <component :is="use" />
    </div>
</template>

<script>
/**
 * 用户协议
 */

import EnUse from "./use_en"
import KrUse from "./use_kr"
import ZhUse from "./use_zh"

export default {
    name: "Use",
    data() {
        return {
            use: null,
        }
    },
    mounted() {
        let lang = this.$i18n.locale
        switch (lang) {
            case "zh":
                this.use = ZhUse
                break
            case "kr":
                this.use = KrUse
                break
            default:
                this.use = EnUse
        }
    },
}
</script>

<style>
.agreement-content-wrapper {
    height: 100vh;
    width: 100wh;
    padding: 48px 32px;
    background-color: aliceblue;
    color: rgb(21, 17, 17);
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}
.agreement-content-wrapper .title {
    font-size: 24px;
    flex-grow: 0;
}
.agreement-content-wrapper .agreement-content_container {
    flex-grow: 1;
}
.mainContent .section2 {
    display: block;
    text-align: center;
}
.section2 .section2Content {
    margin: 16px 0;
    text-align: justify;
}
</style>
