<template>
    <div class="agreement-content_container">
        <p>1. 수집하는 개인 정보 항목</p>
        <p>
            회사는 최초 회원가입 및 원활한 고객상담, 기타 각종 서비스 등 서비스
            제공을 위한 필수정보를 수집하고 있습니다. 그리고 고객 맞춤서비스
            제공, 회사에서 제공한 다양한 서비스를 제공하기 위하여 선택항목을
            수집하고 있습니다.
        </p>
        <p>
            &lt;회원가입이나 서비스 이용 과정에서 수집하는 항목&gt;<br />
            1) 필수항목 : 전화번호, 성별, 닉네임, 생년월일, 직업, 혈액형, 지역,
            키, 체형, 종교, 음주, 흡연 여부, 사진 <br />
            2) 선택항목 : 이메일 주소, 직장, 학교, 연락처, 인종, 위치정보 <br />
            3) 병역사항, 연봉, 차량, 주거형태, 몸무게, 시력, 혼인이력 등은
            추가서비스 및 설문조사를 통해 다양한 정보 수집 및 제공에 필요시
            정보주체가 그 수집에 동의하는 경우 추가로 수집될 수 있습니다.<br />
            4) 서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은
            정보들이 자동으로 생성되어 수집될 수 있습니다. 서비스 이용기록, 접속
            로그(방문 일시), 쿠키, 기기 고유 식별번호(기기 아이디 혹은 IMEI),
            접속 IP주소 정보, 불량 이용 기록 <br />
        </p>
        <p>2. 개인정보의 수집 및 이용목적</p>
        <p>
            1) &quot;애인&quot;의 기본 기능 제공 <br />
            - 매칭, 메시지 등의 기본 기능 서비스를 제공하기 위함 <br />
            2) 회원 식별 및 회원 관리 <br />
            - 본인 확인 및 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지,
            가입 의사 확인, 불만처리 등 민원처리, 고지사항 전달 <br />
            3) 마케팅 및 광고에 활용 <br />
            - 신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스
            제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도
            파악 또는 회원의 서비스 이용에 대한 통계 <br />
            4) 서비스 공지 및 알림 <br />
            - 회사가 제공하는 서비스에 중요한 변경, 개선 등 회원이 알고 있어야
            한다고 판단되는 정보에 대한 전송 <br />
        </p>
        <p>3. 개인정보의 보유 및 이용기간</p>
        <p>
            회사는 회원이 가입신청으로 가입한 날로부터 서비스를 제공하는 기간
            동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다.
            개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및
            이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를
            지체 없이 파기합니다. 이 경우 개인정보는 어떠한 용도로도 열람 또는
            이용할 수 없도록 처리됩니다. 단, 다음의 정보에 대해서는 아래의
            이유로 명시한 기간 동안 보존합니다.<br />
            보존 항목: 이름, 별명, 성별, 생년월일, 거주지, 내 소개, 사진, 직업,
            최종학력 <br />
            보존 근거: 서비스 이용의 혼선 방지, 불법적 사용자에 대한 관련 기관
            수사협조 <br />
            보존 기간: 1년 <br />
            관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이
            관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.<br />
            보존 항목: 서비스 이용기록, 접속 로그, 접속 IP 정보 <br />
            보존 근거: 통신비밀보호법 <br />
            보존 기간: 3개월 <br />
            표시/광고에 관한 기록: 6개월 (전자상거래등에서의 소비자보호에 관한
            법률)<br />
            계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래등에서의
            소비자보호에 관한 법률)<br />
            대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래등에서의
            소비자보호에 관한 법률)<br />
            소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래등에서의
            소비자보호에 관한 법률)
        </p>
    </div>
</template>

<script>
export default {
    name: "ZhUse",
}
</script>
