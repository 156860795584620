<template>
    <div class="agreement-content-wrapper flex flex-col">
        <h1 class="title">{{ $t("page.privacyAgreement") }}</h1>
        <component :is="use" />
    </div>
</template>

<script>
/**
 * 隐私协议
 */

import EnPrivay from "./privacy_en"
import KrPrivay from "./privacy_kr"
import ZhPrivay from "./privacy_zh"

export default {
    name: "Use",
    data() {
        return {
            use: null,
        }
    },
    mounted() {
        let lang = this.$i18n.locale
        switch (lang) {
            case "zh":
                this.use = ZhPrivay
                break
            case "kr":
                this.use = KrPrivay
                break
            default:
                this.use = EnPrivay
        }
    },
}
</script>

<style class="less">
.agreement-content-wrapper {
    height: 100vh;
    width: 100wh;
    padding: 54px 32px;
    background-color: aliceblue;
    color: rgb(21, 17, 17);
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}
.mainContent .section2 {
    display: block;
    text-align: center;
}
.section2 .section2Content {
    margin: 16px 0;
    text-align: justify;
}
</style>
