<template>
    <div class="agreement-content_container">
        <section class="section2">
            <article class="section2Content">
                <h3 class="sectionContentSubTitle">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    长沙盛世隆和网络科技有限公司（以下简称“隆和科技”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br />
                    本协议约定隆和科技与用户之间关于“可乐漂流瓶”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由可乐漂流瓶随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站查阅最新版协议条款。在可乐漂流瓶修改协议条款后，如果用户不接受修改后的条款，请立即停止使用可乐漂流瓶提供的服务，用户继续使用可乐漂流瓶提供的服务将被视为接受修改后的协议。
                </h3>
            </article>

            <article class="section2Content">
                <h2 class="sectionContentTitle">一、帐号注册</h2>
                <p class="sectionContent">
                    1、用户在使用本服务前需要注册一个“可乐漂流瓶”帐号。“可乐漂流瓶”帐号应当使用手机号码绑定注册，请用户使用尚未与“可乐漂流瓶”帐号绑定的手机号码，以及未被可乐漂流瓶根据本协议封禁的手机号码注册“可乐漂流瓶”帐号。可乐漂流瓶可以根据用户需求或产品需要对帐号注册和绑定的方式进行变更，而无须事先通知用户。<br />
                    2、“可乐漂流瓶”系移动社交产品，用户注册时应当授权可乐漂流瓶公开及使用其地功能信息。<br />
                    3、鉴于“可乐漂流瓶”帐号的绑定注册方式，您同意可乐漂流瓶在注册时将使用您提供的手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。<br />
                    4、在用户注册及使用本服务时，可乐漂流瓶需要搜集能识别用户身份的个人信息以便可乐漂流瓶可以在必要时联系用户，或为用户提供更好的使用体验。可乐漂流瓶搜集的信息包括但不限于用户的姓名、性别、年龄、出生日期、身份证号、地址、学校情况、公司情况、所属行业、兴趣爱好、常出没的地方、个人说明；可乐漂流瓶同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">二、服务内容</h2>
                <p class="sectionContent">
                    1、本服务的具体内容由可乐漂流瓶根据实际情况提供，包括但不限于授权用户通过其帐号进行即时通讯、添加好友、加入群组、关注他人、发布留言。可乐漂流瓶可以对其提供的服务予以变更，且可乐漂流瓶提供的服务内容可能随时变更；可乐漂流瓶，在官方公告中展示，用户可留意查看。<br />
                    2、可乐漂流瓶提供的服务包含免费服务与收费服务。用户可以通过付费方式购买收费服务，具体方式为：用户通过微信、支付宝或其他“可乐漂流瓶”平台提供的付费途径支付一定数额的人民币购买“可乐漂流瓶”平台的虚拟货币——星星币，然后根据可乐漂流瓶公布的资费标准以星星币购买用户欲使用的收费服务，从而获得收费服务使用权限。对于收费服务，可乐漂流瓶会在用户付款使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。<br />
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">三、用户个人信息保护</h2>
                <p class="sectionContent">
                    1、用户在注册帐号或使用本服务的过程中，可能需要填写或提交一些必要的个人信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。<br />
                    2、用户个人信息包括：1）用户自行提供的用户个人信息（如注册时填写的手机号码，电子邮件等个人信息，使用服务时提供的共享信息等）；2）其他方分享的用户个人信息；3）可乐漂流瓶为提供服务而合法收集的用户必要个人信息（如使用服务时系统自动采集的设备或软件信息，浏览历史信息，通讯时间信息等技术信息，用户开启定位功能并使用服务时的地理位置信息等）。
                    其中个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在可乐漂流瓶服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。可乐漂流瓶保证在取得用户书面同意的情况下收集、使用或公开用户的个人隐私信息，用户同意可乐漂流瓶无需获得用户的另行确认与授权即可收集、使用或公开用户的非个人隐私信息。<br />
                    3、尊重用户个人信息的私有性是可乐漂流瓶的一贯制度，可乐漂流瓶将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者可乐漂流瓶发现存在发生前述情形的可能时，可乐漂流瓶将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与可乐漂流瓶联系。<br />
                    4、可乐漂流瓶未经用户同意不向任何第三方公开、
                    透露用户个人隐私信息。但以下特定情形除外：<br />
                    (1)
                    可乐漂流瓶根据法律法规规定或有权机关的指示提供用户的个人隐私信息；<br />
                    (2)
                    由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因可乐漂流瓶原因导致的个人隐私信息的泄露；
                    (3) 用户自行向第三方公开其个人隐私信息；<br />
                    (4)
                    用户与可乐漂流瓶及合作单位之间就用户个人隐私信息的使用公开达成约定，可乐漂流瓶因此向合作单位公开用户个人隐私信息；<br />
                    (5)
                    任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；<br />
                    (6) 用户个人信息已经经过处理无法识别特定个人且不能复原。<br />
                    5、用户同意可乐漂流瓶可在以下事项中使用用户的个人隐私信息：<br />
                    (1)
                    可乐漂流瓶向用户及时发送重要通知，如软件更新、本协议条款的变更；<br />
                    (2)
                    可乐漂流瓶内部进行审计、数据分析和研究等，以改进可乐漂流瓶的产品、服务和与用户之间的沟通；<br />
                    (3)
                    依本协议约定，可乐漂流瓶管理、审查用户信息及进行处理措施；<br />
                    (4) 适用法律法规规定的其他事项。<br />
                    除上述事项外，如未取得用户事先同意，可乐漂流瓶不会将用户个人隐私信息使用于任何其他用途。<br />
                    6、可乐漂流瓶重视对未成年人个人信息的保护。可乐漂流瓶将依赖用户提供的个人信息判断用户是否为未成年人。任何18岁以下的未成年人注册帐号或使用本服务应事先取得家长或其法定监护人（以下简称"监护人"）的书面同意。除根据法律法规的规定及有权机关的指示披露外，可乐漂流瓶不会使用或向任何第三方透露未成年人的聊天记录及其他个人信息。除本协议约定的例外情形外，未经监护人事先同意，可乐漂流瓶不会使用或向任何第三方透露未成年人的个人信息。任何18岁以下的用户不得下载和使用。<br />
                    7、因可乐漂流瓶提供的服务系基于地理位置提供的移动社交服务，用户确认，其地理位置信息为非个人隐私信息，用户成功注册“可乐漂流瓶”帐号视为确认授权可乐漂流瓶提取、公开及使用用户的地理位置信息。用户地理位置信息将作为用户公开资料之一，由可乐漂流瓶向其他用户公开以便可乐漂流瓶向用户提供基于地理位置的移动社交服务。如用户需要终止向其他用户公开其地理位置信息，可自行关闭位置权限。<br />
                    8、为了改善可乐漂流瓶的技术和服务，向用户提供更好的服务体验，可乐漂流瓶或可会自行收集使用或向第三方提供用户的非个人隐私信息。<br />
                    9、可乐漂流瓶保证在合法、正当与必要的原则下收集、使用或者公开用户个人信息且不会收集与提供的服务无关的用户个人信息。<br />
                    10、可乐漂流瓶十分注重保护用户的个人隐私，并制定了《可乐漂流瓶隐私权政策》用户亦可以通过“设置”页面里来进行具体查看，用户确认并同意使用可乐漂流瓶提供的服务将被视为接受《可乐漂流瓶隐私权权政策》。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">四、内容规范</h2>
                <p class="sectionContent">
                    1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于帐号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用帐号或本服务所产生的内容。<br />
                    2、用户不得利用“可乐漂流瓶”帐号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：<br />
                    (1) 反对宪法所确定的基本原则的；<br />
                    (2)
                    危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />
                    (3) 损害国家荣誉和利益的；<br />
                    (4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br />
                    (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
                    (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
                    (7)
                    散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br />
                    (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br />
                    (9)
                    不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；<br />
                    (10) 含有法律、行政法规禁止的其他内容的信息。<br />
                    3、用户不得利用“可乐漂流瓶”帐号或本服务制作、上载、复制、发布、传播如下干扰“可乐漂流瓶”正常运营，以及侵犯其他用户或第三方合法权益的内容：<br />
                    (1) 含有任何性或性暗示的；<br />
                    (2) 含有辱骂、恐吓、威胁内容的；<br />
                    (3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br />
                    (4) 涉及他人隐私、个人信息或资料的；<br />
                    (5)
                    侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br />
                    (6)
                    含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。<br />
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">五、使用规则</h2>
                <p class="sectionContent">
                    1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表可乐漂流瓶的观点、立场或政策，可乐漂流瓶对此不承担任何责任。<br />
                    2、用户不得利用“可乐漂流瓶”帐号或本服务进行如下行为：<br />
                    (1)
                    提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br />
                    (2) 强制、诱导其他用户关注、点击链接页面或分享信息的；<br />
                    (3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br />
                    (4) 利用技术手段批量建立虚假帐号的；<br />
                    (5) 利用“可乐漂流瓶”帐号或本服务从事任何违法犯罪活动的；<br />
                    (6)
                    制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br />
                    (7)
                    其他违反法律法规规定、侵犯其他用户合法权益、干扰“可乐漂流瓶”正常运营或可乐漂流瓶未明示授权的行为。<br />
                    3、用户须对利用“可乐漂流瓶”帐号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与可乐漂流瓶无关。如因此给可乐漂流瓶或第三方造成损害的，用户应当依法予以赔偿。<br />
                    4、可乐漂流瓶提供的服务中可能包括广告，用户同意在使用过程中显示可乐漂流瓶和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，可乐漂流瓶不承担任何责任。<br />
                    5、除非可乐漂流瓶书面许可，用户不得从事下列任一行为：<br />
                    (1) 删除软件及其副本上关于著作权的信息；<br />
                    (2)
                    对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；<br />
                    (3)
                    对可乐漂流瓶拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；<br />
                    (4)
                    对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经可乐漂流瓶授权的第三方工具/服务接入软件和相关系统；<br />
                    (5)
                    通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；<br />
                    (6)
                    通过非可乐漂流瓶开发、授权的第三方软件、插件、外挂、系统，登录或使用可乐漂流瓶软件及服务，或制作、发布、传播非可乐漂流瓶开发、授权的第三方软件、插件、外挂、系统。<br />
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">六、虚拟货币</h2>
                <p class="sectionContent">
                    1、可乐漂流瓶将在“可乐漂流瓶”平台发行虚拟货币，即星星币。星星币可用于购买“星星币”平台的增值服务，包括但不限于表情服务及会员服务，除此外，不得用于其他任何用途。该等增值服务的价格均以星星币为单位，具体价格信息将由可乐漂流瓶自行决定并在相关服务页面上显示。<br />
                    2、星星币和人民币的兑换比例依用户购买渠道的不同而有不同的兑换比例，具体兑换比例以用户购买星星币相关渠道服务页面显示为准。可乐漂流瓶有权根据运营情况随时变更上述兑换比例，并将在用户购买星星币相关渠道服务页面显示。<br />
                    3、用户默认已开通星星币账户，可进行星星币购买（下称“充值”）和消费。用户可在设置页面查询到星星币余额、购买记录和消费记录。星星币相关信息将不作为公开信息。<br />
                    4、用户可以通过网上银行、支付宝或其他“可乐漂流瓶”平台提供的充值途径为可乐漂流瓶币账户进行充值。用户使用星星币购买相关收费服务后，可将相关收费服务赠与其他用户。用户确认不会以非法方式或者使用非平台所指定的充值途径进行充值,如果用户违规使用非可乐漂流瓶认可的充值途径非法充值/购买星星币，则可乐漂流瓶不保证充值顺利或正确完成，若因此造成用户权益受损，可乐漂流瓶将不会作出任何补偿或赔偿，可乐漂流瓶同时保留随时终止用户可乐漂流瓶账号资格及使用各项充值服务的权利，并进行相应惩罚。<br />
                    5、用户确认在进行充值前已经仔细确认过自己的账号并仔细选择了相关操作选项，若因用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益的，可乐漂流瓶将不会作出任何补偿或赔偿。<br />
                    6、用户确认，星星币一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。除法律法规明确规定外，星星币账户充值完成后，可乐漂流瓶不予退款。<br />
                    7、用户确认，星星币只能用于购买“可乐漂流瓶”平台上的各类增值服务，任何情况下不得与可乐漂流瓶以外的第三方进行星星币交易，亦不得在除“可乐漂流瓶”平台以外的第三方平台（如淘宝）上进行交易；如违反前述约定，造成用户或第三方任何损失，可乐漂流瓶不负任何责任，且如可乐漂流瓶有理由怀疑用户的星星币账户或使用情况有作弊或异常状况，可乐漂流瓶将拒绝该用户使用星星币进行支付，直至按本协议约定采取相关封禁措施。<br />
                    8、可乐漂流瓶有权基于交易安全等方面的考虑不时设定或修改涉及交易的相关事项，包括但不限于交易限额、交易次数等。用户了解并确认可乐漂流瓶的前述设定或修改可能对用户的交易产生一定的不便，用户对此没有异议。<br />
                    9、用户确认，除法律法规明确规定或本协议另有约定外，用户已购买的任何收费服务不能以任何理由退购（即退换成星星币或法定货币）或调换成其他服务。<br />
                    10、可乐漂流瓶不鼓励未成年人使用虚拟货币服务，未成年人应请监护人操作或在监护人明示同意下操作，否则不得使用本服务。<br />
                    11、因用户自身的原因导致可乐漂流瓶无法提供星星币购买服务或提供星星币购买服务时发生任何错误而产生的任何损失或责任，由用户自行负责，可乐漂流瓶不承担责任，包括但不限于：<br />
                    (1)因用户的可乐漂流瓶账号丢失、被封禁或冻结；<br />
                    (2)用户将密码告知他人导致的财产损失；<br />
                    (3)因用户绑定的第三方支付机构账户的原因导致的任何损失或责任；<br />
                    (4)其他用户故意或者重大过失或者违反法律法规导致的财产损失。<br />
                    12、用户在使用可乐漂流瓶提供的服务时，如出现违反国家法律法规、本协议约定或其他本平台对用户的管理规定的情形，可乐漂流瓶有权暂时或永久封禁用户的账号。账号封禁后至解禁（如有）前，用户账户上的剩余星星币将被暂时冻结或全部扣除，不可继续用于购买平台上的虚拟产品或服务，同时不予返还用户购买星星币时的现金价值。<br />
                    13、用户确认并同意如用户主动注销账号，则用户已充值到账的星星币，购买的虚拟礼物，游戏币以及会员权益等视为自动放弃，可乐漂流瓶不予返还相应的现金价值，也不会作出任何补偿。<br />
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">七、账户管理</h2>
                <p class="sectionContent">
                    1、“可乐漂流瓶”帐号的所有权归可乐漂流瓶所有，用户完成申请注册手续后，获得“可乐漂流瓶”帐号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。可乐漂流瓶因经营需要，有权回收用户的“可乐漂流瓶”帐号。<br />
                    2、用户有责任妥善保管注册帐号信息及帐号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册帐号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的帐号或密码。在用户怀疑他人使用其帐号或密码时，用户同意立即通知可乐漂流瓶。<br />
                    3、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，可乐漂流瓶在通知用户后有权依据协议中断或终止对违约用户“可乐漂流瓶”帐号提供服务。同时，可乐漂流瓶保留在任何时候收回“可乐漂流瓶”帐号、用户名的权利。<br />
                    4、如用户注册“可乐漂流瓶”帐号后一年不登录，可乐漂流瓶可以收回该帐号，以免造成资源浪费，由此造成的不利后果由用户自行承担，用户确认操作之前与可乐漂流瓶相关的所有服务均已进行妥善处理。回收账号后并不代表本可乐漂流瓶账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，用户的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，可乐漂流瓶有限自行终止用户的账号注销并无需另行得到用户的同意。<br />
                    5、用户主动注销账号，注销账号15天内，停止使用任何功能，到时间后系统将删除用户所有账号信息，系统会保留用于名字和头像，移除用于其他所有相关信息。用户后续无法继续使用该用户名进行登陆或注册。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">八、数据储存</h2>
                <p class="sectionContent">
                    1、可乐漂流瓶不对用户在本服务中相关数据的删除或储存失败负责。<br />
                    2、可乐漂流瓶可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。<br />
                    3、如用户停止使用本服务或本服务终止，可乐漂流瓶可以从服务器上永久地删除用户的数据。本服务停止、终止后，可乐漂流瓶没有义务向用户返还任何数据。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">九、风险承担</h2>
                <p class="sectionContent">
                    1、用户理解并同意，“可乐漂流瓶”仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册帐号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对“可乐漂流瓶”及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。可乐漂流瓶无法且不会对因用户行为而导致的任何损失或损害承担责任。<br />
                    如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向可乐漂流瓶举报或投诉，可乐漂流瓶将依本协议约定进行处理。<br />
                    2、用户理解并同意，因业务发展需要，可乐漂流瓶保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">十、知识产权声明</h2>
                <p class="sectionContent">
                    1、除本服务中涉及广告的知识产权由相应广告商享有外，可乐漂流瓶在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归可乐漂流瓶所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。<br />
                    2、除另有特别声明外，可乐漂流瓶提供本服务时所依托软件的著作权、专利权及其他知识产权均归可乐漂流瓶所有。<br />
                    3、可乐漂流瓶在本服务中所涉及的图形、文字或其组成，以及其他可乐漂流瓶标志及产品、服务名称（以下统称“可乐漂流瓶标识”），其著作权或商标权归可乐漂流瓶所有。未经可乐漂流瓶事先书面同意，用户不得将可乐漂流瓶标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理可乐漂流瓶标识的行为。<br />
                    4、上述及其他任何可乐漂流瓶或相关广告商依法拥有的知识产权均受到法律保护，未经可乐漂流瓶或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">十一、法律责任</h2>
                <p class="sectionContent">
                    1、如果可乐漂流瓶发现或收到他人举报或投诉用户违反本协议约定的，可乐漂流瓶有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁
                    、设备封禁 、功能封禁 的处罚，且通知用户处理结果。<br />
                    2、因违反用户协议被封禁的用户，设置页面联系客服查询封禁期限，并在封禁期限届满后自助解封。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可向可乐漂流瓶客服页面提交申诉，可乐漂流瓶将对申诉进行审查，并自行合理判断决定是否变更处罚措施。<br />
                    3、用户理解并同意，可乐漂流瓶有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br />
                    4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿可乐漂流瓶与合作公司、关联公司，并使之免受损害。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    十二、不可抗力及其他免责事由
                </h2>
                <p class="sectionContent">
                    1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，可乐漂流瓶将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，可乐漂流瓶及合作单位在法律允许的范围内免责。<br />
                    2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。<br />
                    3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，可乐漂流瓶不承担任何责任。<br />
                    4、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，可乐漂流瓶不承担任何责任。<br />
                    5、用户理解并确认，可乐漂流瓶需要定期或不定期地对“可乐漂流瓶”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，可乐漂流瓶无需为此承担任何责任。<br />
                    6、可乐漂流瓶依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成可乐漂流瓶的义务或承诺，可乐漂流瓶不能保证及时发现违法违规或违约行为或进行相应处理。<br />
                    7、用户理解并确认，对于可乐漂流瓶向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，可乐漂流瓶无需承担任何责任：<br />
                    (1) 可乐漂流瓶向用户免费提供的服务；<br />
                    (2) 可乐漂流瓶向用户赠送的任何产品或者服务。<br />
                    8、在任何情况下，可乐漂流瓶均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“可乐漂流瓶”或本服务而遭受的利润损失，承担责任（即使可乐漂流瓶已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，可乐漂流瓶对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用可乐漂流瓶提供的服务而支付给可乐漂流瓶的费用(如有)。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">
                    十三、服务的变更、中断、终止
                </h2>
                <p class="sectionContent">
                    1、鉴于网络服务的特殊性，用户同意可乐漂流瓶有权随时变更、中断或终止部分或全部的服务（包括收费服务）。可乐漂流瓶变更、中断或终止的服务，可乐漂流瓶应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，如果该用户已经向可乐漂流瓶支付的星星币，可乐漂流瓶应当按照该用户实际使用服务的情况扣除相应星星币之后将剩余的星星币退还用户的星星币账户中。<br />
                    2、如发生下列任何一种情形，可乐漂流瓶有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：<br />
                    (1)
                    根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；<br />
                    (2) 用户违反相关法律法规或本协议的约定；<br />
                    (3) 按照法律规定或有权机关的要求；<br />
                    (4) 出于安全的原因或其他必要的情形。
                </p>
            </article>
            <article class="section2Content">
                <h2 class="sectionContentTitle">十四、其他</h2>
                <p class="sectionContent">
                    1、可乐漂流瓶郑重提醒用户注意本协议中免除可乐漂流瓶责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。<br />
                    2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和可乐漂流瓶之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交可乐漂流瓶住所地有管辖权的人民法院管辖。<br />
                    3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。<br />
                    4、由于互联网高速发展，您与可乐漂流瓶签署的本协议列明的条款可能并不能完整罗列并覆盖您与可乐漂流瓶所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，可乐漂流瓶隐私权政策、可乐漂流瓶平台行为规范等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用可乐漂流瓶平台服务，视为您同意上述补充协议。<br />
                </p>
            </article>
        </section>
    </div>
</template>

<script>
export default {
    name: "ZhUse",
}
</script>
